import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import {
  Alert,
  CircularProgress,
  IconButton,
  Pagination,
  Paper,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { formatInTimeZone } from "date-fns-tz";
import DownloadIcon from "@mui/icons-material/Download";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import copy from "copy-to-clipboard";
import { get, off, onValue, ref, set } from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { useAuth } from "../../firebase/contexts/AuthContext";
import { TeldioTextField } from "../../MUI_Components/Components";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_AWSACCESSKEY,
  secretAccessKey: process.env.REACT_APP_AWSSECRETKEY,
});

const s3 = new AWS.S3();

const FileUploadViewer = () => {
  const [files, setFiles] = useState([]);
  const [filterQuery, setFilterQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const [sendMessageSuccess, setSendMessageSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { currentUser, logout } = useAuth();
  const [filesInfo, setFilesInfo] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageHovered, setImageHovered] = useState("");
  const baseUrl = "https://s3.us-east-1.amazonaws.com/upload.teldio.com/";

  const handlePopoverOpen = (event, image) => {
    if (isImage(image)) {
      const baseUrl = "https://s3.amazonaws.com/upload.teldio.com/";
      const imageURL = `${baseUrl}${image}`;
      setImageHovered(imageURL);
      setAnchorEl(event.currentTarget);
    }
  };

  function openImage(image) {
    if (isImage(image)) {
      const baseUrl = "https://s3.amazonaws.com/upload.teldio.com/";
      const imageURL = `${baseUrl}${image}`;
      window.open(imageURL, "_blank");
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isImage = (fileName) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const fileExtension = fileName.split(".").pop().toLowerCase();
    console.log(fileExtension);
    return imageExtensions.includes(fileExtension);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const db = getDB();

    // Update to correct path: "/public-uploads/" instead of "/files/"
    const uploadsRef = ref(db, `/public-uploads/`);

    const unsubscribe = onValue(
      uploadsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          console.log("Updated file info:", snapshot.val());
          setFilesInfo(Object.values(snapshot.val())); // Store file info in state
        } else {
          console.log("No data available in /public-uploads/");
          setFilesInfo([]); // Ensure it's an empty array, not undefined
        }
      },
      (error) => {
        console.error("Failed to read data:", error);
      }
    );

    return () => off(uploadsRef, "value", unsubscribe);
  }, [currentUser?.uid]); // Ensure this re-runs when user changes

  const handleCopy = (key) => {
    const fileUrl = `${baseUrl}${key}`;
    copy(fileUrl) ? setCopySuccess(true) : setCopyError(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopySuccess(false);
    setCopyError(false);
    setSendMessageSuccess(false);
  };

  const sendToGoogleChat = (link) => {
    const webhookUrl = `https://chat.googleapis.com/v1/spaces/AAAAi39XrGQ/messages?key=${process.env.REACT_APP_GKEY}&token=${process.env.REACT_APP_GTOKEN}`;
    const message = { text: `Download link: ${link}` };

    fetch(webhookUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(message),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Message sent successfully");
          setSendMessageSuccess(true);
        } else {
          console.error("Failed to send message:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  const handleSendtoBacon = (key) => {
    const fileUrl = `${baseUrl}${key}`;
    sendToGoogleChat(fileUrl);
  };

  const handleDownload = (key) => {
    const fileUrl = `${baseUrl}${key}`;
    window.open(fileUrl, "_blank");
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function getFiletype(inputString) {
    const lastIndex = inputString.lastIndexOf(".");
    if (lastIndex === -1) return "";
    return inputString.substring(lastIndex + 1);
  }
  const getFilteredFiles = () => {
    if (!Array.isArray(files) || files.length === 0) return [];

    return files
      .map((file) => {
        // Normalize file key by removing any known prefix
        const normalizedFileKey = file.key.replace(
          /^fileupload\/|^uploads\//,
          ""
        );

        // Find matching file info regardless of prefix
        const fileInfo =
          filesInfo?.find(
            (info) =>
              info.fileKey.replace(/^fileupload\/|^uploads\//, "") ===
              normalizedFileKey
          ) || {};

        return {
          ...file,
          fileName:
            fileInfo.fileName ||
            normalizedFileKey.replace(/^\d+-[a-f0-9]+-/, ""), // Remove random prefix
          uploader: fileInfo.email || "Unknown",
          description: fileInfo.fileDesc || "No Description",
        };
      })
      .filter((file) => {
        const query = filterQuery.toLowerCase();
        return (
          file.fileName.toLowerCase().includes(query) ||
          file.uploader.toLowerCase().includes(query) ||
          file.description.toLowerCase().includes(query)
        );
      })
      .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
  };

  const totalPages = Math.ceil(
    files.filter(
      (file) =>
        file.key.toLowerCase().includes(filterQuery.toLowerCase()) &&
        file.Size > 0
    ).length / 25
  );

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(
          "https://us-central1-teldiotools.cloudfunctions.net/listFiles"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch files");
        }
        const data = await response.json();
        setFiles(data.files);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching files:", error);
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  return (
    <div style={{ width: "90%", marginLeft: "5%" }}>
      <div style={{ marginTop: "15px", paddingBottom: "50px" }}>
        <TeldioTextField
          label="Search Files"
          variant="outlined"
          value={filterQuery}
          onChange={(e) => setFilterQuery(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <SearchIcon
                position="start"
                sx={{ p: 0.25, color: "#A9A9A9", ml: -0.5, mr: 1 }}
              />
            ),
          }}
          sx={{
            marginBottom: "10px",
          }}
        />
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#A0AD39", marginTop: "30px" }} />
          </div>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>File</TableCell>
                    <TableCell>Uploader</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Last Modified</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {getFilteredFiles().length > 0 && (
                  <TableBody>
                    {getFilteredFiles().map((row) => (
                      <TableRow key={row.key}>
                        <TableCell>
                          {row.fileName ||
                            row.key
                              .replace("fileupload/", "")
                              .replace(/^\d+-[a-f0-9]+-/, "")}
                        </TableCell>

                        <TableCell>{row.uploader}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          {formatInTimeZone(
                            new Date(row.lastModified),
                            "America/New_York",
                            "yyyy-MM-dd HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell>{row.fileType}</TableCell>
                        <TableCell>
                          {formatBytes(row.fileSize || row.size)}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <IconButton
                              aria-label="Download"
                              size="small"
                              onClick={() => handleDownload(row.key)}
                            >
                              <Tooltip title="Download File">
                                <DownloadIcon fontSize="inherit" />
                              </Tooltip>
                            </IconButton>

                            <IconButton
                              aria-label="Link"
                              size="small"
                              onClick={() => handleCopy(row.key)}
                            >
                              <Tooltip title="Copy File Link">
                                <InsertLinkIcon fontSize="inherit" />
                              </Tooltip>
                            </IconButton>

                            <IconButton
                              aria-label="Send to Bacon Chat"
                              size="small"
                              onClick={() => handleSendtoBacon(row.key)}
                            >
                              <Tooltip title="Send to Bacon Chat">🥓</Tooltip>
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, page) => setCurrentPage(page)}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </>
        )}
        <Snackbar
          open={copySuccess}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Link copied to clipboard!
          </Alert>
        </Snackbar>
        <Snackbar
          open={copyError}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Failed to copy link to clipboard. Copy manually.
          </Alert>
        </Snackbar>
        <Snackbar
          open={sendMessageSuccess}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Message sent successfully!
          </Alert>
        </Snackbar>
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
        >
          <img
            src={imageHovered}
            alt="Preview"
            style={{
              maxWidth: "500px",
              maxHeight: "500px",
            }}
          />
        </Popover>
      </div>
    </div>
  );
};

export default FileUploadViewer;
